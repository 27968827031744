window.addEventListener('DOMContentLoaded', () => {
  const burger      = require('./modules/burger'),
        aboutSlider = require('./modules/aboutSlider'),
        smiSlider   = require('./modules/smiSlider');

  burger();
  aboutSlider();
  smiSlider();
})






