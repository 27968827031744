function smiSlider () {
    const swiperSmi = new Swiper('.smi-slider__wrapper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
      
        // If we need pagination
        pagination: {
          el: '.smi-slider__pagination',
          clickable: true
        },
      
        // Navigation arrows
        navigation: {
          nextEl: '.smi-slider__button-next',
          prevEl: '.smi-slider__button-prev',
        },
      
        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },
    });
}

module.exports = smiSlider