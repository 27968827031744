function aboutSlider () {
    const swiper = new Swiper('.swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
      
        // If we need pagination
        pagination: {
          el: '.about-project__pagination',
          clickable: true,
          type: 'bullets',
        },
      
        // Navigation arrows
        navigation: {
          nextEl: '.about-project__button-next',
          prevEl: '.about-project__button-prev',
        },
      
        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },
    });
}

module.exports = aboutSlider;