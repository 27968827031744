function burger () {
    const burger = document.querySelector('.header__burger'),
      navWrapper = document.querySelector('.header__nav');

    const togleBurger = () => {
        burger.addEventListener('click', () =>{
        navWrapper.classList.toggle('adaptiv')
     })
    }
    togleBurger();
    
}

module.exports = burger;